<template>
  <div class="project-detail-container">
    <div class="projectHeader" style="display: none">
      <a
        style="
          float: left;
          line-height: 2rem;
          font-size: 1.25rem;
          margin-left: 1rem;
          font-weight: 600;
        "
      >
        {{ projectName }}
      </a>

      <div></div>
    </div>
    <div class="project-detail-left">
      <div class="project-detail-left-name">
        {{ projectName }}
      </div>
      <Card style="padding-bottom: 0.5rem; margin-top: 1rem">
        <p slot="title" style="text-align: left">
          <Icon type="ios-analytics-outline" />
          Project information
        </p>
        <div class="listItem">
          Creation Time:
          <span style="float: right">{{ creationTime }}</span>
        </div>
        <div class="listItem">
          Last updated:
          <span style="float: right">{{ lastUpdated }}</span>
        </div>
        <div class="listItem">
          Price of executable code:
          <span style="float: right">{{ ECPrice }}</span>
        </div>
        <div class="listItem">
          Share points gain ratio:
          <span style="float: right">{{ pointsPerLine }}</span>
        </div>
        <div class="listItem">
          Number of investors:
          <span style="float: right">{{ investorNum }}</span>
        </div>
        <div class="listItem">
          Number of contributors:
          <span style="float: right">{{ ContributorNum }}</span>
        </div>
        <div class="listItem">
          Number of functions:
          <span style="float: right">{{ functionNum }}</span>
        </div>
        <div class="listItem">
          Lines of Master:
          <span style="float: right">{{ LinesOfMaster }}</span>
        </div>
        <div class="listItem">
          Estimated release time:
          <span style="float: right">{{ EstimatedRT }}</span>
        </div>
        <div class="listItem">
          Voting time for Join/Extend:
          <span style="float: right">{{ VotingTimeForJoin }}</span>
        </div>
        <div class="listItem">
          Voting time for amendment:
          <span style="float: right">{{ VTForAmendment }}</span>
        </div>
        <div class="listItem">
          Has executable code
          <Icon v-if="HasECode" style="float: right" type="md-checkmark" />
          <Icon v-if="!HasECode" style="float: right" type="md-close" />
        </div>
        <div style="margin-top: 0.8rem; text-align: right" v-if="isMember">
          <Button
            style="width: fit-content; text-align: center"
            :to="'/projectEdit?id=' + projectId"
          >
            <Icon type="md-build" style="margin: 0 0.2rem" />
            Edit
          </Button>
        </div>
      </Card>
    </div>
    <div class="project-detail-body">
      <div class="project-detail-body-right">
        <Dropdown v-if="isMember" trigger="click" placement="bottom">
          <Button type="success" style="width: 130px; display: block">
            <Icon type="md-download" />
            Clone
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list" style="width: 21.875rem; z-index: 5">
            <div style="margin: 2px 0; text-align: left; padding-left: 16px">
              <Icon type="ios-cloud-download" />
              <span style="font-weight: 300; margin-left: 0.5rem">Clone</span>
            </div>
            <Tabs
              style="
                padding: 1rem;
                padding-top: 0rem;
                padding-bottom: 0.5rem;
                z-index: 5;
              "
            >
              <TabPane label="HTTPS">
                <Input
                  readonly
                  v-model="HTTPSAddress"
                  style="width: 17.875rem; float: left"
                  id="clonePaneInput"
                />
                <Button
                  icon="md-copy"
                  @click="urlCopy"
                  style="
                    border-radius: 0 5px 5px 0;
                    float: left;
                    left: -0.125rem;
                  "
                ></Button>
                <div style="color: lightcoral">{{ cloneNotice }}</div>
              </TabPane>
            </Tabs>
          </DropdownMenu>
        </Dropdown>
        <Button
          type="info"
          style="width: 130px; margin-top: 15px; display: block"
        >
          <div @click="joinClick()" v-if="!isMember">
            <Icon type="md-person-add" />
            Join
          </div>
          <div @click="checkClick()" v-if="isMember">
            <Icon type="md-add" />
            Extend
          </div>
        </Button>
        <Dropdown trigger="click" placement="bottom">
          <Button
            type="success"
            style="width: 130px; margin-top: 15px; display: block"
          >
            <Icon type="ios-cart-outline" />
            Invest/Buy
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list" style="width: 200px">
            <Button type="text" class="buyListItem" @click="buyClick('buy')">
              <span style="float: left">Executable code:</span>
              <span style="float: right">{{ ECPrice }}</span>
            </Button>
            <Button type="text" class="buyListItem" @click="buyClick('invest')">
              <span style="float: left">Source code:</span>
              <span style="float: right">{{ SCPrice }}</span>
            </Button>
          </DropdownMenu>
        </Dropdown>
        <Button
          type="info"
          style="width: 130px; margin-top: 15px; display: block"
          v-if="isMember"
        >
          <div @click="exchangeClick()">
            <Icon type="md-swap" />
            exchange
          </div>
        </Button>
        <div v-if="isMember">
          <div class="smallTitle">Your profit:</div>
          <div class="bigNum">{{ userProfit }}</div>
        </div>
        <Divider v-if="isMember" style="margin: 0.5rem 0" />
        <div>
          <div class="smallTitle">Total profit:</div>
          <div class="bigNum">{{ totalprofits }}</div>
        </div>
        <div>
          <div class="smallTitle">Code Approve Rate:</div>
          <div class="bigNum">{{ codeApproveRate }}</div>
        </div>
        <Card
          v-if="isMember"
          style="
            margin: 1.2rem 1rem 5px 1rem;
            margin-top: 1.2rem;
            background-color: #fffffa;
          "
        >
          <div>
            <div class="listItem">
              ownership shares:
              <span style="float: right">{{ userConPt }}</span>
            </div>
            <div class="listItem">
              Unexecuted shares:
              <span style="float: right">{{ userAvailablePt }}</span>
            </div>
          </div>
        </Card>
      </div>
      <div class="project-detail-body-left">
        <div class="project-detail-body-left-pageheader">
          <router-link :to="'/projectList'"> ◀ return </router-link>
        </div>
        <div class="project-detail-body-left-name">
          Open Artifacts Of {{ projectName }}
          <span class="project-detail-body-left-name-icon">
            <Poptip trigger="hover">
              <Icon
                type="md-help-circle"
                @click="
                  () => {
                    this.showHelp = !this.showHelp;
                  }
                "
              />
              <div slot="content">show help</div>
            </Poptip>
          </span>
          <div class="project-detail-body-left-info" v-if="showHelp">
            If you want to experience the project, please click Run Demo; if you
            want to know more open information, please click Ask for more
            details; if you want to join the project, please click Join in the
            upper right corner; if you If you are the developer of the project,
            please click Extend in the upper right corner to approve the
            application and answer; if you want to purchase the execution code
            of the project or invest in the project, please click Invest/Buy;
          </div>
        </div>
        <div class="project-detail-body-left-rundemo">
          <div style="float: left">
            <Button type="text" style="padding-left: 0rem" @click="runDemo">
              <span style="font-size: 32px; color: #e9ab01"
                ><Icon type="md-play" color="#e9ab01" size="32" />Run Demo</span
              >
            </Button>
          </div>
          <a
            href="javascript:void(0)"
            @click="ask()"
            style="
              display: block;
              text-align: right;
              padding-right: 1rem;
              line-height: 3rem;
              font-size: 1rem;
              color: #2db7f5;
            "
          >
            <Icon type="ios-chatbubbles-outline" />Ask for more details
          </a>
        </div>
        <div class="project-detail-body-left-introduction">
          <div class="introduction-block">
            <span class="introduction-block-title"> Abstract:<br /> </span>
            <span class="introduction-block-content"
              >{{ abstract
              }}<span
                v-if="!abstract"
                class="introduction-block-content-default"
                >(empty)</span
              ></span
            >
          </div>
          <div class="introduction-block">
            <span class="introduction-block-title">Environment: </span>
            <span class="introduction-block-content"
              >{{ environment
              }}<span
                v-if="!environment"
                class="introduction-block-content-default"
                >(empty)</span
              ></span
            >
          </div>
          <div class="introduction-block">
            <span class="introduction-block-title">Languages: </span>
            <span class="introduction-block-content"
              >{{ languages
              }}<span
                v-if="!languages"
                class="introduction-block-content-default"
                >(empty)</span
              ></span
            >
          </div>
          <div class="introduction-block">
            <span class="introduction-block-title">Dependencies: </span>
            <span class="introduction-block-content"
              >{{ dependencies
              }}<span
                v-if="!dependencies"
                class="introduction-block-content-default"
                >(empty)</span
              ></span
            >
          </div>
        </div>
      </div>
      <div class="project-detail-body-bottom">
        <Menu
          mode="horizontal"
          theme="light"
          :active-name="activeTab"
          ref="funcMenu"
          @on-select="menuSelect"
        >
          <MenuItem name="Function">
            <Icon type="md-cube" />
            Functions
          </MenuItem>
          <MenuItem name="Code" v-if="isMember">
            <Icon type="ios-paper" />
            Files
          </MenuItem>
          <MenuItem name="Issues" v-if="isMember">
            <Icon type="ios-people" />
            Issues
          </MenuItem>
          <MenuItem name="PullRequests" v-if="isMember">
            <Icon type="md-git-pull-request" />
            Converge
          </MenuItem>
          <MenuItem name="Contributions" v-if="isMember">
            <Icon type="ios-podium" />
            Contributors
          </MenuItem>
        </Menu>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showHelp: false,
      copyDisplay: false,
      projectId: "",
      isMember: false,
      projectName: "",
      activeTab: "",
      abstract: "",
      environment: "",
      languages: "",
      dependencies: "",
      creationTime: "",
      lastUpdated: "",
      totalInvestment: "",
      investorNum: 0,
      ContributorNum: 0,
      totalprofits: 0,
      codeApproveRate:"0/0",
      functionNum: 0,
      ECPrice: "",
      SCPrice: "",
      userProfit: "",
      userConPt: "",
      userExecutedPt: "",
      userAvailablePt: "",
      userRank: 0,
      userConRank: 0,
      profitChange: "",
      EstimatedRT: "",
      pointsPerLine: "",
      LinesOfMaster: "123",
      VotingTimeForJoin: "",
      VTForAmendment: "",
      HasECode: true,
      ECpath: "",
      HTTPSAddress: "",
      cloneNotice: "You should replace @ in your email with %40.",
    };
  },
  methods: {
    favBtnClick() {
      switch (this.favBtnType) {
        case "primary":
          this.favBtnType = "default";
          break;
        case "default":
          this.favBtnType = "primary";
          break;
      }
    },
    buyClick(val) {
      if (val == "buy") {
        this.$router.push("/payment?mode=buy&projectId=" + this.projectId);
      } else {
        this.$router.push("/payment?mode=invest&projectId=" + this.projectId);
      }
    },
    joinClick() {
      this.$router.push("/createJoin?id=" + this.projectId);
    },
    checkClick() {
      this.$router.push("/checkJoin?id=" + this.projectId);
    },
    exchangeClick() {
      this.$router.push("/exchangeCode?projectId=" + this.projectId);
    },
    cloudDemo() {},
    menuSelect(name) {
      switch (name) {
        case "Code":
          this.$router.push("/code?id=" + this.projectId);
          break;
        case "Issues":
          this.$router.push("/issues?id=" + this.projectId);
          break;
        case "PullRequests":
          this.$router.push("/pullRequests?id=" + this.projectId);
          break;
        case "Function":
          this.$router.push("/function?id=" + this.projectId);
          break;
        case "Contributions":
          this.$router.push("/projectContribution?id=" + this.projectId);
          break;
      }
    },
    init() {
      if (this.$cookies.isKey("user_name")) {
        var identity = this.$cookies.get("user_identity");
        if (identity == "member") {
          this.isMember = true;
        } else {
          this.isMember = false;
        }
      } else {
        this.isMember = false;
      }

      var Tself = this;
      var val = this.$route.query.id;
      console.log(val);
      this.projectId = val;

      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "get",
        url: `/project/getCodeApproveRate?projectId=${this.projectId}`,
      }).then(function (response) {
        var items = response.data.data.items
        console.log(items)
        Tself.codeApproveRate = items
      });

      this.$axios({
        headers: {
          "Content-Type": "application/text",
        },
        method: "post",
        url: "/project/findById",
        data: val,
      })
        .then(function (response) {
          var data = response.data.data.items;
          Tself.projectFounderId = data.founder;
          Tself.environment = data.environment;
          Tself.projectName = data.name;
          Tself.abstract = data.abstractInfo;
          Tself.languages = data.languages;
          Tself.dependencies = data.dependencies;
          Tself.creationTime = data.creationTime;
          Tself.lastUpdated = data.updateTime;
          Tself.investorNum = data.investorNumber;
          Tself.ContributorNum = data.contributionNumber;
          Tself.totalprofits = data.totalProfit;
          Tself.functionNum = data.functionNumber;
          Tself.ECPrice = data.executableCodePrice;
          Tself.LinesOfMaster = data.codeLines;
          Tself.SCPrice = data.sourceCodePrice;
          Tself.EstimatedRT = data.releaseTime;
          Tself.pointsPerLine = data.linesForGetOneShare;
          Tself.VotingTimeForJoin = data.votingTimeForJoin;
          Tself.VTForAmendment = data.votingTimeForAmendment;
          if (data.executableCodePath != "") {
            Tself.HasECode = true;
          } else {
            Tself.HasECode = false;
          }
          Tself.ECpath = data.executableCodePath;
          Tself.hierarchyDiagramPath = data.hierarchyDiagramPath;
        })
        .catch(function (error) {
          console.log(error);
        });
      if (this.$cookies.isKey("user_name")) {
        this.$axios({
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
          url: "/contribution/findUniqueContribution",
          data: {
            projectId: Tself.projectId,
            userId: Tself.$cookies.get("user_id"),
          },
        })
          .then(function (response) {
            var items = response.data.data.items;
            Tself.$cookies.set("contributionId", items.id);
            Tself.isMember = true;
            (Tself.userProfit = items.profit),
              (Tself.userConPt = items.shares),
              (Tself.userAvailablePt = items.shares - items.executedShares);
            Tself.profitChange = items.profitInThisWeek;

            Tself.$axios({
              headers: {
                "Content-Type": "application/json",
              },
              method: "get",
              url:
                "/pit/getUrl?contributionId=" +
                Tself.$cookies.get("contributionId"),
            })
              .then(function (response) {
                let data1 = response.data;
                console.log(data1);
                console.log(data1.split("//")[1]);
                let copyUrl =
                  "git clone https://email:password@" + data1.split("//")[1];
                Tself.HTTPSAddress = copyUrl;
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            Tself.isMember = false;
            console.log(error);
          });
      }
    },
    ask() {
      let Tself = this;
      let hasLogin = true;
      let val = Tself.$cookies.get("user_id");
      if (val === null) {
        hasLogin = false;
      }
      if (hasLogin) {
        this.$router.push("/furtherInfo?id=" + this.projectId);
      } else {
        this.$Message.warning("please login first");
      }
    },
    runDemo() {
      let Tself = this;
      let hasLogin = true;
      let val = Tself.$cookies.get("user_id");
      if (val === null) {
        hasLogin = false;
      }
      if (hasLogin) {
        //this.$Message.warning('The project has not yet uploaded a demo on the cloud platform');
        //this.$router.push('/furtherInfo?id='+this.projectId)
        window.open("http://47.106.171.65:6080/vnc.html?password=123456");
      } else {
        this.$Message.warning("please login first");
      }
    },
    urlCopy() {
      let httpsURL = document.createElement("input");
      httpsURL.value = this.HTTPSAddress;
      document.body.appendChild(httpsURL);
      httpsURL.select();
      // var httpsURL = document.getElementById("httpsClone");
      // httpsURL.value = this.HTTPSAddress;
      // httpsURL.select();
      // let oInput = document.createElement('copyElement');
      // oInput.value = this.HTTPSAddress;
      // document.body.appendChild(oInput);
      // console.log(oInput.value)
      // oInput.select();
      document.execCommand("Copy");
      this.$Message.success("Copy!");
      httpsURL.remove();
    },
    // fakeInitial(){
    // 	this.projectName = 'projectName';
    // 	this.favBtnType = 'default';
    // 	this.favNum = 0;
    // 	this.activeTab = 'Function';
    // 	this.abstract = `The Visual Understanding Environment (VUE) project at Tufts' Academic Technology department provides faculty and students with tools to successfully integrate digital resources into their teaching and learning. VUE provides a visual environment for structuring, presenting, and sharing digital information and an OKI-compliant software bridge for connecting to FEDORA-based digital repositories.`;
    // 	this.environment = `Software libraries, File systems, Web sites, Human factors, Educational products, Software tools`;
    // 	this.languages = 'java: 63%, vue: 37%';
    // 	this.dependencies = `Visual Understanding Environment, VUE project, Tufts Academic Technology, teaching, learning, OKI-compliant software bridge, FEDORA-based digital repositories`
    // 	this.creationTime = "2019.6.4";
    // 	this.lastUpdated = "2020.12.13";
    // 	this.investorNum = 12;
    // 	this.ContributorNum = 7;
    // 	this.totalprofits = '$37000';
    // 	this.functionNum = 13;
    // 	this.ECPrice = "$66.6";
    // 	this.SCPrice = "$160";
    // 	this.userProfit = '$49.6';
    // 	this.userConPt = '19121';
    // 	this.userExecutedPt = '10000';
    // 	this.userAvailablePt = '9121';
    // 	this.userConRank = 2;
    // 	this.profitChange = '+123';
    // 	this.EstimatedRT = '2222.12.12';
    // 	this.pointsPerLine = '190';
    // 	this.VotingTimeForJoin = '5 minutes';
    // 	this.VTForAmendment = '7 days';
    // 	this.HasECode = true;
    // }
  },
  mounted() {
    this.init();
    this.activeTab = "Function";
    this.$nextTick(() => {
      if (this.$route.fullPath.indexOf("issues") != -1) {
        this.activeTab = "Issues";
      }
      this.$refs.funcMenu.updateActiveName();
    });
  },
};
</script>

<style lang="less">
.project-detail-container {
  height: calc(~"100vh - 60px");
  overflow: auto;
  .project-detail-left {
    width: 300px;
    float: left;
    padding: 25px;
    &-name {
      font-size: 1.25rem;
      padding-left: 2rem;
      font-weight: 600;
      text-align: left;
    }
  }
  .project-detail-body {
    float: left;
    width: calc(~"100% - 300px");
    height: 100%;
    overflow: auto;
    padding: 25px;
    &-left {
      width: calc(~"100% - 300px");
      position: relative;
      &-pageheader {
        position: absolute;
        font-size: 1rem;
        font-weight: 600;
      }
      &-name {
        font-size: 1.25rem;
        font-weight: 600;
        border-bottom: #e7e7e7 0.0625rem solid;
        padding-bottom: 25px;
        &-icon {
          cursor: pointer;
        }
      }
      &-info {
        font-weight: 600;
        font-size: 0.5rem;
        color: red;
        margin-top: 15px;
      }
      &-rundemo {
        border-bottom: #e7e7e7 0.0625rem solid;
        margin-top: 1rem;
        padding-bottom: 0.5rem;
      }
      &-introduction {
        text-align: left;
        margin-right: 2rem;
        .introduction-block {
          margin: 10px 0;
          font-size: 1.125rem;
          &-title {
            font-weight: 700;
          }
          &-content {
            &-default {
              color: #bbb;
            }
          }
        }
      }
    }
    &-right {
      width: 300px;
      padding-left: 25px;
      float: right;
      text-align: left;
      .smallTitle {
        color: darkgray;
        font-size: 0.875rem;
        margin: 0.5rem 0;
        vertical-align: middle;
      }
      .bigNum {
        width: 100%;
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        color: #e9ab01;
        font-size: 2rem;
        font-weight: 700;
      }
    }
    &-bottom {
      float: left;
      width: 100%;
    }
  }
}
.projectHeader {
  margin: 20px 0;
  width: 100%;
  height: 1.5rem;
  padding: 0 3.125rem;
}
.contentCard {
  background: #ffffff;
  padding: 4rem;
  padding-top: 2rem;
}
.ivu-btn > span {
  width: 100%;
}
.listItem {
  margin: 0.8rem 0;
  text-align: left;
}
.buyListItem {
  width: 100%;
  margin: 0.5rem 0;
}
</style>
